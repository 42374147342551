<template>
  <sec-menu>
    <div class="title">TIPO DE PESSOA</div>
    <ul>
      <li><a @click="$router.replace({name: 'pessoas'})" :class="{active: tipo === null}"><u-icon name="address-card" type="fa" /> Qualquer</a></li>
      <li><a @click="$router.replace({name: 'pessoas', query: {type: 'pessoas'}})" :class="{active: tipo == 1}"><u-icon name="user" type="fa" /> Pessoas</a></li>
      <li><a @click="$router.replace({name: 'pessoas', query: {type: 'organizacoes'}})" :class="{active: tipo == 2}"><u-icon name="building" type="fa" /> Organizações</a></li>
    </ul>

    <div class="title m-t-lg">TIPO DE PAPEL</div>
    <ul>
      <li v-for="p in papers" :key="p.code"><a @click="$router.replace({name: 'pessoas', query: {paper: p.code}})" :class="{active: $route.query.paper === p.code}">{{ p.name }}</a></li>
    </ul>
    <slot />
  </sec-menu>
</template>

<script>
import SecMenu from "@/components/layout/components/SecMenu"
export default {
  name: "MenuTipoPessoa",
  props: ['tipo'],
  data () {
    return {
      papers: [
        {
          code: 'arrematante',
          name: 'Leiloeiro'
        },
        {
          code: 'colaborador',
          name: 'Colaborador'
        },
        {
          code: 'fornecedor',
          name: 'Fornecedor'
        }
      ]
    }
  },
  components: {SecMenu},
}
</script>

